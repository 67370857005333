<template>
  <main class="cloud-store">
    <AppPreview
      title="Облачное хранилище"
      text="Ваш сервер любой конфигурации на отказоустойчивой площадке"
    >
      <img src="/images/cloud-store.png" alt="preview">
    </AppPreview>

    <section class="info">
      <div class="container">
        <h2>Инфраструктура будущего - в облаке</h2>

        <p class="info__text">
          В эру победившего «клауда» вопрос перехода от информационных систем on-premise к подлинно облачной модели становится определяющим. Поэтому Вам будут нужны проверенные и безопасные сервисы для решения конкретных задач, созданные заказчиками и партнерами.
        </p>
      </div>
    </section>

    <section class="calc">
      <div class="container">
        <h2>{{ extraData.title }}</h2>

        <div class="calc-items">
          <CalcItem
            v-for="item of list"
            :key="item.id"
            v-model:current="item.current"
            v-model:enabled="item.enabled"
            :min="item.min"
            :max="item.max"
            :step="item.step"
            :label="item.label"
            :unit="item.unit"
            :globalEnabled="enabled"
          />
        </div>
      </div>
    </section>

    <section
      class="container"
      style="margin-top: 60px;"
    >
      <PanelTotal
        :price="price"
        @open="$refs.requestModal.open()"
      />
    </section>

    <ModalRequest
      :total="price"
      ref="requestModal"
      @submit="onSubmit"
    >
      <AppReceipt
        :receipt="receipt"
      />
    </ModalRequest>

    <ModalThank ref="modalThank" />
  </main>
</template>

<script>
import useCloudStore from '@/composables/useCloudStore'

import AppReceipt from '@/components/AppReceipt'
import ModalRequest from '@/components/ModalTemplates/ModalRequest'
import ModalThank from '@/components/ModalTemplates/ModalThank'
import PanelTotal from '@/components/PanelTotal'
import AppPreview from '@/components/AppPreview'
import CalcItem from '@/components/CalcItem'

export default {
  name: 'pageCloudStore',
  components: {
    AppReceipt,
    ModalRequest,
    ModalThank,
    PanelTotal,
    AppPreview,
    CalcItem
  },
  setup () {
    const {
      extraData,
      requestBody,
      list,
      price,
      receipt,
      enabled
    } = useCloudStore()

    return {
      extraData,
      requestBody,
      list,
      price,
      receipt,
      enabled
    }
  },
  methods: {
    async onSubmit (event) {
      const { surname, name, patron, companyName, email, phone } = event

      try {
        const res = await this.$api.common.sendClientData({
          surname, name, patronymic: patron, companyName, email, phone
        })
        const applicationId = res.data?.applicationId

        await this.$api.cloudStore.sendRequest({
          applicationId,
          service: this.requestBody,
          price: this.price
        })

        this.$refs.requestModal.close()
        this.$refs.requestModal.clear()
        this.$refs.modalThank.open()
      } catch (e) {
        alert('Произошла ошибка!')
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cloud-store {
  .info {
    padding: 40px 0;
    &__text {
      font-size: 18px;
      line-height: 1.6;
      margin-top: 40px;
      max-width: 970px;
    }
  }
  .calc {
    padding: 40px 0 0 0;

    &-items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      margin-top: 22px;

      @media screen and (max-width: $media-lg) {
        grid-template-columns: 1fr;
      }
    }

    .panel {
      margin-top: 60px;
      display: flex;
      align-items: center;
      &__btn {
        padding: 0 50px;
      }
      &__sum {
        font-weight: 500;
        font-size: 24px;
        margin-left: 30px;
        span {
          color: $primary-color;
        }
      }
    }
  }
}
</style>
